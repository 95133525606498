@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway-VariableFont_wght.ttf') format('truetype'); /* Passen Sie den Pfad an */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway-VariableFont_wght.ttf') format('truetype'); /* Passen Sie den Pfad an */
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.5;
  word-break: break-word;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style-type: initial; /* oder 'decimal' für geordnete Listen */
  padding-left: 20px; /* Platz für die Einrückung */
}
